/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { isDev } from 'src/config';
import rootReducer, { RESET_STORE_ACTION_TYPE } from './rootReducer';
import { jobPositionsQuery } from '../api/rtkQuery/jobPositionsQuery';
import { departmentsQuery } from '../api/rtkQuery/departmentsQuery';
import { usersQuery } from 'src/api/rtkQuery/usersQuery';
import storeChangeEvent from './storeChangeEvent';
import api from 'src/api';
import { persistReducer, persistStore } from 'redux-persist';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import storage from 'redux-persist-indexeddb-storage';
import { channelTransform } from './transforms';

export const store = configureStore({
  reducer: persistReducer({
    key: 'root',
    storage: storage('conversationDB'),
    transforms: [channelTransform],
    whitelist: ['chatPageV2'],
  }, rootReducer),

  devTools: isDev,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      thunk: {
        extraArgument: {
          api,
        },
      },
      serializableCheck: false,
    });

    if (isDev) {
      const logger = createLogger({ collapsed: true, duration: true });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      middlewares.push(logger as any);
    }

    middlewares.push(
      jobPositionsQuery.middleware,
      departmentsQuery.middleware,
      usersQuery.middleware,
    );

    return middlewares;
  },
  preloadedState: rootReducer(undefined, { type: RESET_STORE_ACTION_TYPE, payload: undefined }),
});

export const persistor = persistStore(store);

export type AppDispatchType = typeof store.dispatch;
export type AppStateType = ReturnType<typeof rootReducer>;
export type AppThunkConfigType = {
  state: AppStateType;
  dispatch: AppDispatchType;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

declare module 'react-redux' {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/naming-convention
  export interface DefaultRootState extends AppStateType { }
}

export const useAppDispatch: typeof useDispatch<AppDispatchType> = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector;

export const resetStore = () => store.dispatch({ type: RESET_STORE_ACTION_TYPE });

store.subscribe(() => {
  const state = store.getState();
  storeChangeEvent.dispatch(state);
});

export default store;
